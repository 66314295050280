<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tpm.driver_assign') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="driver-assign" :class="'btn btn-success text-light'">{{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-overlay :show="load">
                <b-col lg="12" sm="12">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                  <b-row>
                    <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Batch" vid="batch_no" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="batch_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.batch_no"
                                    :options="batchList"
                                    id="batch_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                    </b-row>
                </b-form>
              </ValidationObserver>
              <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                    <b-row class="p-4">
                      <b-col style="background-color: #177903;" lg="6" class="offset-lg-3 p-2">
                        <template>
                          <h6 style="color:white;" class="text-sm-center">{{ $t('elearning_tpm.driver_assign') }} {{ $t('globalTrans.details') }}</h6>
                        </template>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="6">
                            <ValidationProvider name="Trainer"  vid="trainer_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="trainer_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.trainer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="detailsFormData.trainer_id"
                                  :options="trainerList"
                                  id="trainer_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=0>{{loading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <table class="table" style="width:100%;color: #177903;" v-if="detailsFormData.training_date">
                              <tr>
                                <th style="width: 25%">{{$t('elearning_tpm.session_start_time')}}</th>
                                <th style="width: 5%">:</th>
                                <th style="width: 55%">
                                  <slot>
                                    {{ start_time | time12FormateTraining }} , {{ session_date | dateFormat }}
                                  </slot>
                                </th>
                                <th style="width: 25%">{{$t('elearning_tpm.session_end_time')}}</th>
                                <th style="width: 5%">:</th>
                                <th style="width: 25%">
                                  <slot>
                                    {{ end_time | time12FormateTraining }} , {{ session_date | dateFormat }}
                                  </slot>
                                </th>
                                <th></th>
                              </tr>
                            </table>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Training Date" vid="training_date" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_date"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('globalTrans.select_date') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="detailsFormData.training_date"
                              :options="sessionDateList"
                              id="training_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value=0>{{ trainerLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Pick Up Type" vid="pick_up_type" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pick_up_type"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_tpm.pick_up_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="detailsFormData.pick_up_type"
                              :options="pickUpTypeList"
                              id="pick_up_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Driver" vid="driver_id" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="driver_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_tpm.driver_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="detailsFormData.driver_id"
                              :options="driverList"
                              id="driver_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Driver Contact Number" vid='driver_contact' rules="required|min:11|max:11">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="driver_contact"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_tpm.driver_contact_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="driver_contact"
                              v-model="detailsFormData.driver_contact"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Start Time" vid="start_time" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="start_time"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('elearning_tpm.pickup_time') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="time"
                                v-model="detailsFormData.start_time"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Vanue Name (En)" vid="venu_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="venu_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('elearning_tpm.location_en') }}
                          </template>
                          <b-form-input
                            id="venu_name"
                            v-model="detailsFormData.location_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Vanue Name (Bn)" vid="venu_name_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="venu_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('elearning_tpm.location_bn') }}
                          </template>
                          <b-form-input
                            id="venu_name_bn"
                            v-model="detailsFormData.location_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col>
                        <div class="text-right mb-2">
                          <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                          <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              <br/>
            <!-- List  -->
            <b-row id="form">
            <div class="col-md-12">
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.arrival')}}</legend>
                  <b-col md="12" class="table-responsive">
                    <table class="table">
                      <thead class="thead">
                        <tr>
                          <th style="width:15%">{{$t('globalTrans.date')}}</th>
                          <th style="width:15%">{{$t('globalTrans.time')}}</th>
                          <th style="width:20%">{{$t('elearning_tpm.driver_name')}}</th>
                          <th style="width:20%">{{$t('elearning_tpm.driver_contact_number')}}</th>
                          <th style="width:18%">{{$t('elearning_config.trainer')}}</th>
                          <th style="width:22%">{{ $t('elearning_tpm.location') }}</th>
                          <th style="width:10%">{{ $t('globalTrans.action') }}</th>
                        </tr>
                      </thead>
                      <tr v-for="(item,index) in formData.details.filter(type => type.pick_up_type == 1)" :key="index">
                        <td>
                          {{ item.training_date | dateFormat }}
                        </td>
                        <td>
                          {{ item.start_time | time12FormateTraining }}
                        </td>
                        <td>
                          {{ getDriver(item.driver_id) }}
                        </td>
                        <td>
                          {{ item.driver_contact | mobileNumber }}
                        </td>
                        <td>
                          {{ getTrainer(item.trainer_id) }}
                        </td>
                        <b-td>
                          {{ (currentLocale === 'bn') ? item.location_bn : item.location_en }}
                        </b-td>
                        <td>
                          <span class="d-flex text-center">
                            <b-button @click="editItem(item, index, item.pick_up_type)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <!-- <b-button @click="editDetails(item)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                            <b-button type="button" @click="deleteItem(item, index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </span>
                        </td>
                      </tr>
                      <template v-if="formData.details.filter(type => type.pick_up_type == 1).length === 0">
                        <tr>
                          <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                        </tr>
                      </template>
                    </table>
                  </b-col>
              </fieldset>
            </div>
          </b-row>
            <b-row id="form">
            <div class="col-md-12">
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.departure')}}</legend>
                  <b-col md="12" class="table-responsive">
                    <table class="table">
                      <thead class="thead">
                        <tr>
                          <th style="width:15%">{{$t('globalTrans.date')}}</th>
                          <th style="width:15%">{{$t('globalTrans.time')}}</th>
                          <th style="width:20%">{{$t('elearning_tpm.driver_name')}}</th>
                          <th style="width:20%">{{$t('elearning_tpm.driver_contact_number')}}</th>
                          <th style="width:18%">{{$t('elearning_config.trainer')}}</th>
                          <th style="width:22%">{{ $t('elearning_tpm.location') }}</th>
                          <th style="width:10%">{{ $t('globalTrans.action') }}</th>
                        </tr>
                      </thead>
                      <tr v-for="(item,index) in formData.details.filter(type => type.pick_up_type == 2)" :key="index">
                        <td>
                          {{ item.training_date | dateFormat }}
                        </td>
                        <td>
                          {{ item.start_time | time12FormateTraining }}
                        </td>
                        <td>
                          {{ getDriver(item.driver_id) }}
                        </td>
                        <td>
                          {{ item.driver_contact | mobileNumber }}
                        </td>
                        <td>
                          {{ getTrainer(item.trainer_id) }}
                        </td>
                        <b-td>
                          {{ (currentLocale === 'bn') ? item.location_bn : item.location_en }}
                        </b-td>
                        <td>
                          <span class="d-flex text-center">
                            <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <b-button type="button" @click="deleteItem(item, index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </span>
                        </td>
                      </tr>
                      <template v-if="formData.details.filter(type => type.pick_up_type == 2).length === 0">
                        <tr>
                          <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                        </tr>
                      </template>
                    </table>
                  </b-col>
              </fieldset>
            </div>
          </b-row>
            <b-row class="text-right">
              <b-col>
                <!-- <b-button type="button" @click="saveUpdate" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                <!-- <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button> -->
                <router-link to="driver-assign" class="btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
              </b-col>
            </b-row>
                </b-col>
              </b-overlay>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{detailsFormData}}</pre>
    <pre>{{formData}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularList, driverAssignUpdate, driverAssignStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
    }
    if (this.$route.query.id) {
      this.getData(this.$route.query.id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      flag_status: 1,
      errors: [],
      editIndex: '',
      type: '',
      formData: {
        id: '',
        sheduleId: 0,
        circular_memo_no: '',
        batch_no: 0,
        fiscal_year_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        // detailsFormData: {},
        details: [
        ]
      },
      detailsFormData: {
        sl: 1,
        trainer_id: 0,
        mobile: '',
        user_id: 0,
        pick_up_type: 0,
        driver_id: 0,
        driver_contact: '',
        training_date: '',
        start_time: '',
        location_en: '',
        location_bn: ''
      },
      trainers: [],
      scheduleRemove: [],
      scheduleListData: false,
      editloading: false,
      scheduleList: [],
      trainerLoading: false,
      trainingTypeList: [],
      trainingCategoryList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      guestTrainerListData: [],
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      trainerListData: [],
      listBatch: [],
      circularList: [],
      arrivalList: [],
      departureList: [],
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      courseModuleError: '',
      circularLoading: false,
      moduleLoading: false,
      trainerErrors: [],
      selectDate: [],
      sessionDate: [],
      start_time: '',
      end_time: '',
      session_date: ''
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    // loading: function () {
    //   return this.$store.state.commonObj.loading
    // },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    guestTrainerList: function () {
      const listObject = this.guestTrainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    trainingScheduleSetup: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.filter(item => item.value !== 3)
    },
    driverList: function () {
      const listObject = this.$store.state.TrainingElearning.commonObj.driverList.filter(item => item.org_id === this.formData.org_id)
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    sheduleType: function () {
      return this.$store.state.TrainingElearning.commonObj.sheduleType
    },
    courseList: function () {
      return this.$store.state.TrainingElearning.commonObj.courseList
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    pickUpTypeList () {
      const pickUpTypeList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Arrival' : 'আগমন', text_en: 'Arrival', text_bn: 'আগমন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Departure' : 'প্রস্থান', text_en: 'Departure', text_bn: 'প্রস্থান' }
      ]
      return pickUpTypeList
    },
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    sessionDateList: function () {
      const listObject = this.selectDate
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.session_date, text_en: obj.session_date, text_bn: obj.session_date }
        } else {
          return { value: obj.value, text: obj.session_date, text_en: obj.session_date, text_bn: obj.session_date }
        }
      })
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
        this.getAssignedTrainerList()
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    },
    'detailsFormData.trainer_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.trainerListData.find(allBatchListData => parseInt(allBatchListData.value) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.detailsFormData.user_id = allBatchListData.user_id
          this.detailsFormData.mobile = allBatchListData.mobile
        }
        this.getTrainingDataList()
      }
    },
    'detailsFormData.driver_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDriverContact()
      }
    },
    'detailsFormData.training_date': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getScheduleTime()
      }
    }
  },
  methods: {
    cancelItem () {
        this.detailsFormData = {}
        this.editIndex = ''
        this.$refs.form1.reset()
    },
    async saveUpdate () {
        if (!this.formData.details.length) {
          return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Add at least one item!',
            color: 'D6E09B'
          })
        }
        let result = null
        this.load = true
        if (this.$route.query.id) {
          result = await RestApi.putData(trainingElearningServiceBaseUrl, `${driverAssignUpdate}/${this.$route.query.id}`, this.formData)
        } else {
          result = await RestApi.postData(trainingElearningServiceBaseUrl, driverAssignStore, this.formData)
        }
        if (result.success) {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.load = false
          this.$router.push('/training-e-learning-service/tpm/driver-assign')
          } else {
          this.load = false
          this.$refs.form.setErrors(result.errors)
        }
    },
    editItem (item, index) {
        this.detailsFormData = Object.assign({}, item)
        this.editIndex = index
    },
    editDetails (data) {
      this.detailsFormData = {
        editable: true,
        newSl: data.newSl,
        trainer_id: data.trainer_id,
        pick_up_type: data.pick_up_type,
        driver_id: data.driver_id,
        driver_contact: data.driver_contact,
        training_date: data.training_date,
        start_time: data.start_time,
        location_en: data.location_en,
        location_bn: data.location_bn
      }
    },
    deleteItem (item) {
      const datas = []
      this.formData.details.map(detail => {
        if (detail.sl !== item.sl) {
          datas.push(detail)
        }
      })
      this.formData.details = datas
    },
    async addItem () {
        const isData = this.formData.details.find(details => parseInt(details.sl) === parseInt(this.detailsFormData.sl))
        if (typeof isData === 'undefined') {
          this.formData.details.push(this.detailsFormData)
        } else {
          const details = []
          this.formData.details.map((obj, index) => {
            if (parseInt(obj.sl) === parseInt(this.detailsFormData.sl)) {
              const newObj = obj
              newObj.trainer_id = this.detailsFormData.trainer_id
              newObj.driver_id = this.detailsFormData.driver_id
              newObj.user_id = this.detailsFormData.user_id
              newObj.mobile = this.detailsFormData.mobile
              newObj.driver_contact = this.detailsFormData.driver_contact
              newObj.training_date = this.detailsFormData.training_date
              newObj.pick_up_type = this.detailsFormData.pick_up_type
              newObj.start_time = this.detailsFormData.start_time
              newObj.location_en = this.detailsFormData.location_en
              newObj.location_bn = this.detailsFormData.location_bn
              details.push(newObj)
            } else {
              details.push(obj)
            }
          })
          this.formData.details = details
        }
        this.detailsFormData = {
          sl: this.formData.details.length + 1,
          trainer_id: this.detailsFormData.trainer_id,
          driver_id: 0,
          driver_contact: '',
          pick_up_type: 0,
          training_date: this.detailsFormData.training_date,
          start_time: '',
          user_id: this.detailsFormData.user_id,
          mobile: this.detailsFormData.mobile,
          location_en: '',
          location_bn: ''
        }
    },
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    getDriverContact () {
      const listObject = this.$store.state.TrainingElearning.commonObj.driverList.find(item => item.org_id === this.formData.org_id && item.value === this.detailsFormData.driver_id)
      if (listObject) {
        this.detailsFormData.driver_contact = listObject.mobile
      } else {
        this.detailsFormData.driver_contact = ''
      }
    },
    getScheduleTime () {
      const listObject = this.selectDate.find(allBatchListData => allBatchListData.value === this.detailsFormData.training_date)
      if (listObject) {
        this.start_time = listObject.start_time
        this.end_time = listObject.end_time
        this.session_date = listObject.session_date
      } else {
        this.start_time = ''
        this.end_time = ''
        this.session_date = ''
      }
    },
    async getAssignedTrainerList () {
        if (this.formData.circular_memo_no) {
            this.loading = true
            const serchData = {
                circular_memo_no: this.formData.circular_memo_no,
                batch_no: this.formData.batch_no
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/assign-trainer-list', serchData)
            if (result.success) {
                this.trainerListData = result.data
                this.loading = false
            } else {
                this.trainerListData = []
                this.loading = false
            }
        }
    },
    getDate (id) {
      const trainingType = this.sessionDateList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getDriver (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.driverList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainer (id) {
      const trainingType = this.trainerListData.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    async getTrainingDataList () {
      if (this.detailsFormData.trainer_id) {
        this.trainerLoading = true
        const serchData = {
            circular_memo_no: this.formData.circular_memo_no,
            batch_no: this.formData.batch_no,
            trainer_id: this.detailsFormData.trainer_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/training-data-list', serchData)
        if (result.success) {
            this.selectDate = result.data
            this.trainerLoading = false
            this.getScheduleTime()
        } else {
            this.selectDate = []
            this.trainerLoading = false
        }
      }
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    // addAll () {
    //   let speaker = ''
    //   let speakerBn = ''
    //     const trainerObj = this.trainerListData.find(doc => doc.value === parseInt(this.formData.trainer_id))
    //     if (typeof trainerObj !== 'undefined') {
    //         speaker = trainerObj.text_en
    //         speakerBn = trainerObj.text_bn
    //     }
    //   let driver = ''
    //   let driverBn = ''
    //   const driverObj = this.$store.state.TrainingElearning.commonObj.driverList.find(item => item.org_id === this.formData.org_id && item.value === this.formData.driver_id)
    //     if (typeof driverObj !== 'undefined') {
    //       driver = driverObj.text_en
    //       driverBn = driverObj.text_bn
    //     }
    //   let tDate = ''
    //   const dateObj = this.selectDate.find(item => item.value === this.formData.training_date)
    //     if (typeof dateObj !== 'undefined') {
    //       tDate = dateObj.session_date
    //     }
    //   const checkData = {
    //     id: this.formData.sheduleId,
    //     circular_memo_no: this.formData.circular_memo_no,
    //     batch_no: this.formData.batch_no,
    //     fiscal_year_id: this.formData.fiscal_year_id,
    //     office_type_id: this.formData.office_type_id,
    //     office_id: this.formData.office_id,
    //     org_id: this.formData.org_id,
    //     training_category_id: this.formData.training_category_id,
    //     training_type_id: this.formData.training_type_id,
    //     training_title_id: this.formData.training_title_id,
    //     training_schedule_setup_id: this.formData.training_schedule_setup_id,
    //     other_title: this.formData.other_title,
    //     other_title_bn: this.formData.other_title_bn,
    //     other_name: this.formData.other_name,
    //     other_name_bn: this.formData.other_name_bn,
    //     course_id: this.formData.course_id,
    //     trainer_id: this.formData.trainer_id,
    //     driver: driver,
    //     driver_bn: driverBn,
    //     t_date: tDate,
    //     driver_contact: this.formData.driver_contact,
    //     trainer_other: this.formData.trainer_other,
    //     training_date: this.formData.training_date,
    //     start_time: this.formData.start_time,
    //     end_time: this.formData.end_time,
    //     venue: this.formData.venue,
    //     venue_bn: this.formData.venue_bn,
    //     speaker: speaker,
    //     speaker_bn: speakerBn
    //   }
    //   if (this.formData.sheduleId) {
    //     const datas = this.scheduleList.map((item, index) => {
    //       if (item.id === this.formData.sheduleId) {
    //         return Object.assign({}, item, checkData)
    //       } else {
    //         return Object.assign({}, item)
    //       }
    //     })
    //     this.scheduleList = datas
    //   } else {
    //     this.scheduleList.push(checkData)
    //   }
    //   this.sheduleReset()
    // },
    // sheduleReset () {
    //   this.formData.course_id = 0
    //   this.formData.other_title_bn = ''
    //   this.formData.other_title = ''
    //   this.formData.other_name = ''
    //   this.formData.other_name_bn = ''
    //   this.formData.training_schedule_setup_id = 0
    //   this.formData.trainer_id = 0
    //   // this.formData.training_date = null
    //   this.formData.start_time = ''
    //   this.formData.end_time = ''
    //   this.formData.sheduleId = 0
    //   this.formData.venue = ''
    //   this.formData.venue_bn = ''
    //   this.$refs.form.reset()
    // },
    // removeAttachment (index, data) {
    //   this.formData.module_attachments.splice(index, 1)
    //   if (data.pre_attachment) {
    //     this.formData.module_attachments_remove.push(data)
    //   }
    // },
    getCategoryList (typeId) {
      if (this.formData.training_type_id) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.formData.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    async getData (id) {
      this.loader = true
      const resultData = await RestApi.getData(trainingElearningServiceBaseUrl, 'tpm/driver-assign/show' + '/' + id)
      const tmpData = resultData.data
      this.formData = tmpData
      const detail = this.formData.details.map((item, index) => ({ ...item, sl: index + 1 }))
      this.formData.details = detail
      this.loader = false
    }
  }
}
</script>
